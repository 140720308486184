
import React, {useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import AuthPage from './pages/AuthPage';
import UserPage from './pages/UserPage';
import QRDetails from './pages/QRDetails';
import './App.css';

// Define your route constants
const ROUTE_HOME = '/home';
const ROUTE_AUTH = '/auth';
const ROUTE_QR = '/QR/:QRID';
const ROUTE_ITEM = '/Item/:QRID';

// Define your routes with route constants and components
const routes = [
  {path: ROUTE_AUTH, component: <AuthPage/> },
  { path: ROUTE_HOME, component: <UserPage /> },
  { path: ROUTE_QR, component: <QRDetails /> }
  //{ path: ROUTE_ITEM, component: <ViewPage /> },
  // ... other routes
];

function App() {
  const [token, setToken] = useState(null);

  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<LandingPage/>} />
              {routes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.component} />
              ))}
          </Routes>
      </BrowserRouter>
  );
}

export default App;