import { useEffect, useState} from 'react';
import {QRDisplay, extractQR} from '../component/QRDisplay';
import Header from '../component/Header';
import instance from '../component/axiosAPI';
import QR from "../images/QR.svg";
import './css/UserPage.css';

const QR_URL = 'users/QR';

const UserPage = () => {
  const [items, setItems] = useState([]);
  const [printItems, setPrintItems] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [newQR, setNewQR] = useState('');
  const [QRSwitch, setQRSwitch] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function fetchQR() {
      try {
        setMessage("Getting QR codes...");
        const response = await instance.get(QR_URL);
        setItems(response?.data?.data || []);
        setMessage("Got QR codes successfully.");
        setLoaded(true);
      } catch (error) {
        setMessage(`Error fetching QR codes: ${error}`);
        console.log(error);
      }
    }
    fetchQR();
  }, []);

  const handleAddQRClick = async () => {
    try {
      await instance.post(
        QR_URL,
        { name: newQR },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      ).then((res)=>{
        if(res.data.qr)  setItems([...items, res.data.qr])
      });
      setNewQR('');
    } catch (error) {
      console.log(error);
    }
  };
  
  function handleExtractQRClick() {
    extractQR(printItems)
      .then(() => {
        console.log('Extraction completed successfully');
      })
      .catch((error) => {
        console.error('Error during extraction:', error);
      });
  }

  function handlePrintSwitch() {
    setQRSwitch(!QRSwitch);
  }

  function handleAddPrintItem(item) {
    setPrintItems((prevItems) => [...prevItems, item]);
  }  

  function handleDeletePrintItem(index) {
    const updatedItems = printItems.filter((_, i) => _[1] !== index);
    setPrintItems(updatedItems);
  }

  if (!isLoaded) {
    return (
      <>
        <p className="loading">{message}</p>
      </>
    );
  } else {
    return (
      <>
        <Header LoggedIn ={true}/>
        <main className="main-section">
          {QRSwitch ? (
              <div className="top-content">
                <div className="container top-content-details">
                  <img src={QR} alt="QR" />
                  <button className="button button-back" onClick={handlePrintSwitch}>Back</button>
                </div>
                <div className="container top-content-details">
                  <img src={QR} alt="QR" />
                  <button className="button button-back" onClick={handleExtractQRClick}>Print QR</button>
                </div>
              </div>
          ) : (
              <div className="top-content">
                <div className="container top-content-details">
                  <img src={QR} alt="QR" />
                  <button className="button-export" onClick={handlePrintSwitch}>Export QR</button>
                </div>
                <div className="container top-content-details">
                  <div className="add-box">
                    <input className="input" type="text" value={newQR} onChange={(e) => setNewQR(e.target.value)} />
                  </div>
                  <i className="fa fa-search" aria-hidden="true"></i>
                  <button className="button-add" onClick={handleAddQRClick}>Add QR</button>
                </div>
              </div>
          )}
          <QRDisplay items={items} displayType={QRSwitch} handleAddPrintItem={handleAddPrintItem} handleDeletePrintItem={handleDeletePrintItem}/>
        </main>
      </>
    );
  }
};

export default UserPage;
