import React, { useRef, useState, useEffect, useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import instance from '../component/axiosAPI';
import LoadingPopup from '../component/LoadingPopup';
import './css/AuthPage.css';
import logo from '../images/logo.png';

const LOGIN_URL = 'users/SignIn';
const SignUp_URL = '/users/signup';

const LoginForm = ({handleSignUp}) => {
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    const [email, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [error, setErrMsg] = useState('');

    const navigate = useNavigate();

    const [processRequests, setProcessRequests] = useState(false)

    const errorMessages = {
      400: 'Missing Email or Password',
      401: 'Unauthorized',
      default: 'Login Failed',
      noUser: 'Something went wrong'
  };

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    const handleSubmit = async (e) => {
      e.preventDefault();
      setProcessRequests(true);
  
      try {
          if (!validateForm()) {
              setErrMsg('Please check your response');
          } else {
              const response = await instance.post(
                  LOGIN_URL,
                  { email, password }
              );
              const user = response?.data?.user;
              if (!user) {
                  setErrMsg(errorMessages.noUser);
              } else {
                  setUser('');
                  setPassword('');
                  navigate('/home');
              }
          }
      } catch (err) {
          const responseStatus = err?.response?.status;
          const errorCode = responseStatus || 'default';
          setErrMsg(errorMessages[errorCode]);
      } finally {
        setProcessRequests(false);;
      }
    };

    const validateForm = () => {
      const errors = {};
    
      // Email validation
      if (!email) {
        errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        errors.email = 'Invalid Email';
      }
    
      // Password validation
      if (!password) {
        errors.password = 'Password is required';
      } else if (password.length < 8) {
        errors.password = 'Password must be at least 8 characters long';
      }
    
      if (Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        setErrMsg(errors[firstErrorField]);
        return false;
      }
    
      // Clear any previous error messages
      setErrMsg('');
      return true;
    };
    

    return (
      <>
        <div className="login form-container">
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                ref={userRef}
                autoComplete="off"
                value={email}
                required
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                autoComplete="off"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <button 
                type='button'
                onClick={handleSignUp}
                className="auth-recover-button">
                Forgot password?
              </button>
            </div>
            <div className="form-group-auth-recover">
              <button type="submit" className="login-button">Login</button>
            </div>
            {error && <p ref={errRef} className="error-message">{error}</p>}
          </form>
          <div className="form-group-text1">
                <span>Don't have an account?</span>
                <button 
                onClick={handleSignUp}
                className = "button-text"
                > Sign in here </button>
            </div>
          {
            processRequests? <LoadingPopup/> : <></>
          }
          {
            /*
            <div className="form-group-alternative">
              <hr/>
              <span>Or Sign In With</span>
              <hr/>
            </div>
            */  
          }
        </div>  
    </>
    );
}


const SignUpForm = ({handleSignIn}) => {
  const [email, setEmail] = useState('');
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const errRef = useRef();

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await instance.post(SignUp_URL, {
        email,
        username,
        password
      }, {
        withCredentials: true
      });

      // You can add code here to handle successful sign up, such as redirecting to a success page.

    } catch (err) {
      setError(err.response.data.error);
    }
  }

  return (
  <>
    <div className="form-container">
      <form onSubmit={handleSignUp} className="signup-form">
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            autoComplete="off"
            onChange={(e) => setUserName(e.target.value)}
            required
            aria-label="Username"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value)}
            required
            aria-label="Email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            autoComplete="off"
            onChange={(e) => setPassword(e.target.value)}
            required
            aria-label="Password"
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            autoComplete="off"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            aria-label="Confirm Password"
          />
        </div>
        <div className="form-group">
          <button type="submit">Sign Up</button>
        </div>
        {error && <p ref={errRef} className="error-message">{error}</p>}
        <div className="form-group-text1">
            <span>Already have an account?</span>
            <button 
              onClick={handleSignIn}
              className = "button-text"
            > Sign in here </button>
        </div>
      </form>
      <div  className = "disclaimer">
        <p> By creating an account you agree to our</p>
        <button>Terms of Service</button><span>and</span> <button>Privacy Policy</button> 
      </div>
    </div>
  </>
);
}

const AuthPage = () => {
    const [authState, setAuthState] = useState(null);

    const handleSignIn = () => {
        setAuthState('login');
    };

    const handleSignUp = () => {
        setAuthState('signup');
    };

    const renderAuthContent = () => {
        if (authState === null) {
            return (
                <>
                    <h1>Welcome!</h1>
                    <h4>Organizing in a pinch</h4>
                    <div className="div-button">
                        <button
                            onClick={handleSignIn}
                            className="auth-button-1"
                            type="button">
                            Login
                        </button>
                        <button
                            onClick={handleSignUp}
                            className="auth-button-2"
                            type="button">
                            Sign Up
                        </button>
                    </div>
                </>
            );
        } else if (authState === 'login') {
            return <LoginForm handleSignUp={handleSignUp} />;
        } else if (authState === 'signup') {
            return <SignUpForm handleSignIn={handleSignIn} />;
        }
    };

    return (
        <main>
            <section className="authentication-section">
                <div className="auth-box container">
                    <div className="authentication-form-container">
                        <img src={logo} alt="logo" />
                        {renderAuthContent()}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AuthPage;
