import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import './css/QRDisplay.css';
import QRCode from 'qrcode';


const generateQRCode = async (QRValue, canvasRef, qrCodeSize) => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      await QRCode.toCanvas(canvas, QRValue, { width: qrCodeSize, height: qrCodeSize });
    }
};
  
const getQRCodeSize = (width) => {
    if (width > 1199) {
        return 200;
    } else if (width < 767) {
        return 100;
    } else {
        return 150;
    }
};

const useQRCodeGeneration = (QRValue, canvasRef) => {
    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const qrCodeSize = getQRCodeSize(screenSize.width);
        generateQRCode(QRValue, canvasRef, qrCodeSize);
    }, [QRValue, screenSize]);

    useEffect(() => {
        const handleResize = () => {
        setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
};

const UserQR = (props) => {
    const QRValue = `${process.env.REACT_APP_FRONTEND_URL}/Item/${props.QRID}`;
    const navigate = useNavigate();
    const canvasRef = useRef(null);

    useQRCodeGeneration(QRValue, canvasRef);

    const handleClick = () => {
      navigate(`/QR/${props.QRID}`);
      window.location.reload(); // Force a page refresh
    };
  
    return (
      <button onClick={handleClick}>
        <canvas ref={canvasRef} />
        <h5>{props.QRTitle}</h5>
      </button>
    );
  };
  
const PrintQR = (props) => {
    const QRValue = `${process.env.REACT_APP_FRONTEND_URL}/Item/${props.QRID}`;
    const [QRSelect, setQRSelect] = useState(false);
    const canvasRef = useRef(null);

    useQRCodeGeneration(QRValue, canvasRef);

    function handleClick() {
        const res = !QRSelect;
        setQRSelect(res);
        if (res) {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            QRCode.toCanvas(canvas, QRValue, { width: canvas.clientWidth, height: canvas.clientHeight }, (error) => {
            if (error) {
                console.error(error);
                return;
            }
            props.addPrintItem([canvas.toDataURL(), props.QRTitle]);
            });
        }
        } else {
        props.deletePrintItem(props.QRTitle);
        }
    }

    return (
        <button className={QRSelect ? 'selected' : 'unselected'} onClick={handleClick}>
            <canvas ref={canvasRef} />
            <h5>{props.QRTitle}</h5>
        </button>
    );
};

async function extractQR(printItems) {
    return new Promise(async (resolve, reject) => {
      try {
        const zip = new JSZip();
        const images = [];
  
        for (const printItem of printItems) {
          const imageDataUrl = printItem[0];
          const response = await fetch(imageDataUrl);
          const blob = await response.blob();
          if (blob.size > 0) {
            images.push([blob, printItem[1]]);
          }
        }
  
        // Create a canvas element to draw titles on images
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        let processedImages = 0;
  
        for (const [imageBlob, title] of images) {
          // Use a FileReader to read the image blob
          const reader = new FileReader();
          reader.onload = function () {
            const img = new Image();
            img.src = reader.result;
  
            img.onload = function () {
              const imageWidth = img.width;
              const titleText = title;
              const titleFontSize = 24; // Adjust the font size as needed
              const fontWidth = ctx.measureText(titleText).width;
  
              // Calculate the width required for the canvas
              const requiredWidth = Math.max(imageWidth, fontWidth) + 20; // Add some padding (e.g., 20 pixels)
  
              canvas.width = requiredWidth;
              canvas.height = img.height + 40; // Adjust the height to fit the title (e.g., 40 pixels)
  
              // Clear the canvas and fill with a white background
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.fillStyle = 'white';
              ctx.fillRect(0, 0, canvas.width, canvas.height);
  
              // Calculate the horizontal position for the image to center it
              const imageX = (canvas.width - imageWidth) / 2;
  
              // Calculate the horizontal position for the title to start from the left edge
              const titleX = 20;
  
              // Draw the image
              ctx.drawImage(img, imageX, 0);
  
              // Customize the text style for the title
              ctx.font = `bold ${titleFontSize}px 'PT Sans', sans-serif`;
              ctx.fillStyle = 'black';
  
              // Draw the title below the image
              ctx.fillText(titleText, titleX, img.height + 30);
  
              // Convert the canvas to a blob in PNG format
              canvas.toBlob((blob) => {
                if (blob) {
                  // Add the image with title to the ZIP archive
                  zip.file(`${title}_${processedImages + 1}.png`, blob);
  
                  processedImages++;
  
                  if (processedImages === images.length) {
                    // All images processed, generate ZIP
                    zip.generateAsync({ type: 'blob' }).then(function (zipFile) {
                      // Save the ZIP file
                      saveAs(zipFile, 'qr_codes.zip');
                      resolve();
                    });
                  }
                }
              }, 'image/png');
            };
          };
          reader.readAsDataURL(imageBlob);
        }
      } catch (error) {
        reject(error);
      }
    });
}
  

const QRDisplay = (props) =>{
    return(
        <section className="qr-section">
            {
              props.items?
                props.items.map((QR) =>
                  props.displayType ? (
                  <PrintQR
                      key={QR._id}
                      QRID={QR._id}
                      QRTitle={QR.name}
                      deletePrintItem={props.handleDeletePrintItem}
                      addPrintItem={props.handleAddPrintItem}
                  />
                  ) : (
                  <UserQR key={QR._id} QRID={QR._id} QRTitle={QR.name} />
                  )
              ):(<></>)
            }
        </section>
    )
}

export {QRDisplay, extractQR};