// LoadingPopup.js
import React from 'react';
import './css/LoadingPopup.css';

const LoadingPopup = () => {
  return (
    <div className="loading-popup">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingPopup;
