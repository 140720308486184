import Header from "../component/Header";
import img_landingpage from "../images/frontpage.jpg"
import "./css/LandingPage.css"


const LandingPage = () =>{
    return (
        <>
            <Header LoggedIn={false}></Header>
            <div className="img-container">
                <img src={img_landingpage} alt="Landing Page Image"/>
            </div>
            <div className = "container">

                <div className = "about-section">
                    <h1> About</h1>
                    <h4> QRganise brings commercial inventory management to domestic houses.</h4>
                    <div>
                        <h3> Efficient </h3>
                        <a> QR codes can store a significant amount of data in a small space. <br/>They allow for quick and easy data transfer, reducing the need for manual data entry</a>
                        <h3> Ease of use </h3>
                        <a> Scanning a QR code with a smartphone or QR code reader is simple and convenient, making it accessible to a wide range of users</a>
                        <h3> Cost Effective </h3>
                        <a> QR codes can be generated and used without significant costs. They can be pritned on various media, including labels, posters, and digital displays</a>
                        <h3> Inventory management </h3>
                        <a> QR codes are valuable in tracking adn managing inventory and assets in businesses and industries, simplifying data retrieval</a>
                    </div>
                </div>
                
                <div>
                    <h1> How it works</h1> 
                    <ol>
                        <li> Create a dashboard: Create and login and user details to begin adding QRcodes</li>
                        <li> Generate QR codes: Click 'Add QR' for each inventory item and include all information about the inventory item. Then click 'Add Item'</li>
                        <li> Label your inventory: Print and affix QR code labels to each item. These labels should be easily scannable and resistant to wear and tear.</li>
                        <li> Track your inventory: Use QRganise to scan and view the inventory linked to the QR code</li>
                        <li> Add and Remove items: when you receive new inventory, scan the QR codes of incoming items to add them to your database. Likewise, when you sell or dispose of items, update your database by removing them</li>
                        <li> Location tracking: if your inventory is spread accross multiple locations, use QR codes on shelves or storage bins. This allows you to track the location of items within your facility</li>
                        <li> Mobile Scanning Devices: Use your smartphone to access all of your QR data</li>
                        <li> By managing your inventory with QR codes, you can significantly improve accuracy, streamline operations, and save time, ultimately leading to more efficient inventory management</li>
                    </ol>
                </div>
                
                

            </div>
            
        </> 
    
    )
}

export default LandingPage;
