// index.js or App.js (whichever is the root component)
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

const RootComponent = () => {
  // Set the page title when the component mounts
  useEffect(() => {
    document.title = 'QRganise';
  }, []);

  return (
    <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
};

root.render(<RootComponent />);

reportWebVitals();
