import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import instance from "../component/axiosAPI";
import "./css/Header.css";

const LOGOUT_URL = "users/SignOut";

const Header = (props) =>{
    return(props.LoggedIn ? <Header_LoggedIn/>: <Header_Base/>)
}

const Header_Base = () =>{
    return (
        <header className="header">
          <div className="logo">
            <Link to="/home">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="navbar">
            <ul>
              <li>
              <Link to = "/auth">Log In</Link>
              </li>
            </ul>
          </div>
        </header>
      );
}

const Header_LoggedIn = () => {
  const handleLogout = () => {
    instance
      .post(LOGOUT_URL, null, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
      .then(() => {
        // On successful logout, navigate to the homepage
        window.location.href = "/"; // Full page reload to reset the application state
      })
      .catch((error) => {
        // Handle any error that occurred during logout
        console.error("Logout failed:", error);
      });
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/home">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="navbar">
        <div className="search-box">
          <input type="search" placeholder="Search..." />
          <i className="fa fa-search" aria-hidden="true"></i>
        </div>
        <ul>
          <li>
            <span className="profile-link">Profile</span>
            <ul className="dropdown-menu">
              <li>
                <button onClick={handleLogout}>Log Out</button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
