import React, { useEffect, useState } from 'react';

const ErrorMessage = (props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // message is empty (meaning no errors). Adjust as needed
    if (!props.message) {
      setVisible(false);
      return;
    }

    // error exists. Display the message and hide after 5 secs
    setVisible(true);

    const timer = setTimeout(() => {
      setVisible(false);
      props.setErrMsg('');
    }, 5000);

    return () => clearTimeout(timer);
  }, [props.message, props.setErrMsg]); // executes every time `message` or `setErrMsg` changes

  if (!visible) return null;

  return (
    <div>
      <p>
        <strong>{props.type}:</strong> {props.message}
        <br />
      </p>
    </div>
  );
};

export default ErrorMessage;
