import {useEffect, useState} from 'react';
import {QRDisplay, extractQR} from '../component/QRDisplay';
import instance from '../component/axiosAPI';
import {useParams} from 'react-router-dom';
import Header from '../component/Header';
import ErrorMessage from '../component/ErrorMessage';
import './css/QRDetails.css';
import QRimg from "../images/QR.svg";

const QR_URL = 'QR/Search/';
const Block_URL = 'QR/Block/';
const USERQR_URL = 'users/QR/';

const Links = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [links, setLinks] = useState(props.links);
  const [changedlinks, setChangedLinks] = useState(props.links)

  function toggleModal() {
    setShowModal(!showModal);
  }

  function toggleEdit() {
    setChangedLinks(links)
    setIsEdit(!isEdit);
  }

  function addLink() {
    setChangedLinks([...changedlinks, '']); // Add an object with a link property
  }

  function handleLinkChange(e, index) {
    const updatedLinks = [...changedlinks];
    let newLink = e.target.value;

    // Check if the link starts with 'http://' or 'https://', and add it if missing
    if (!newLink.startsWith('http://') && !newLink.startsWith('https://')) {
      newLink = 'http://' + newLink;
    }
    updatedLinks[index] = newLink;
    setChangedLinks(updatedLinks);
  }

  async function handleConfirm(){
    setLinks(changedlinks);
    setIsEdit(false)
    props.updateLinks(changedlinks); // Call the updateLinks function here
  }

  function openLink(url) {
    window.open(url, '_blank'); // Open the link in a new tab/window
  }

  return (
    <div>
      <button onClick={toggleModal}>Links</button>
      {showModal && (
        <div className="modal-overlay">
          <div className="container popup">
            <div className = "title-area">
              {isEdit?
                <button  onClick={toggleEdit}> Undo </button>:
                <button  onClick={toggleEdit}>Edit Links</button>
              }
              {isEdit && <button onClick={addLink}>Add Link</button>}
              <button className= 'remove-item' onClick={toggleModal}>X</button>
            </div>
            <div className = "links">
              {isEdit ? (
                changedlinks.map((item, index) => (
                  <input
                    key={index}
                    value={item}
                    onChange={(e) => handleLinkChange(e, index)}
                  />
                ))
              ) : (
                links.map((item, index) => 
                  <button 
                    key={index} 
                    onClick={() => openLink(item)}>
                      {item}
                  </button>
                  )
              )}
            </div>
            {isEdit && <button onClick={handleConfirm}>Confirm</button>}
          </div>
        </div>
      )}
    </div>
  );
};

const ItemEntry = (props) => {
  return (
    props.item.locked ? (
      <div className='display-item current container'>
        <div className='title-area'>
          <h2>{props.item.title}</h2>
          <button className= 'remove-item' onClick={(e) => props.deleteItem(props.index)}>X</button>
        </div>
        <div className ='text-row'>
          <label>Description</label>
          <p>{props.item.description}</p>
        </div>
        <div className='number-row'>
          <label>Quantity:</label>
          <input
            value={props.item.quantity}
            name='quantity'
            placeholder='quantity'
            type='number'
            min={0}
            onChange={(e) => props.handleFormChange(e, props.index)}
          />
        </div>
        <Links
            links={props.item.links}
            name="links"
            updateLinks={(newLinks) => props.updateLinks(newLinks, props.index)}
          />
      </div>
    ) : (
        <div className = 'display-item new-items container'>
          <div className='title-area'>
            <div className='form-group'>
              <label>Title</label>
              <input
                name='title'
                placeholder='title'
                type='text'
                onChange={(e) => props.handleFormChange(e, props.index)}
              />
            </div>
            <button className= 'remove-item' onClick={(e) => props.deleteItem(props.index)}>X</button>
          </div>
          
          <div className='form-group'>
            <label>Description</label>
            <textarea
              name='description'
              placeholder='description'
              type='text'
              onChange={(e) => props.handleFormChange(e, props.index)}
            />
          </div>
          <div className ='form-group'>
            <label>Quantity</label>
            <input
              name='quantity'
              placeholder='quantity'
              type='number'
              min={0}
              onChange={(e) => props.handleFormChange(e, props.index)}
            />
          </div>
          <Links
            links={props.item.links}
            name="links"
            updateLinks={(newLinks) => props.updateLinks(newLinks, props.index)}
          />
      </div>
    )
  );
};

const BlockEdit = (props) => {
  return (
    <section className="edit">
      <h3 className="current-items title">{props.title}</h3>

      <div className="current-items">
      
        {
            props.items.map((item, index) => (
            <ItemEntry
              item={item}
              index={index}
              key={index}
              handleFormChange={props.handleItemDetailsChange}
              deleteItem={props.deleteItem}
              addItem={props.addItem}
              updateLinks={props.updateLinksForItem} // Pass the function here
            />))
        }
        
      </div>
      <div className='add-item'>
        <button onClick={props.addItem}>Add new item</button>
      </div>
      
  </section>
    
  )
}

const SubQREdit = (props) =>{
  return(
    <section className="sub-qr-edit display">
      <div className="container">
        <QRDisplay items={props.items} displayType={props.displayType} handleAddPrintItem={props.handleAddPrintItem} handleDeletePrintItem={props.handleDeletePrintItem}></QRDisplay>
      </div>
    </section>
  )
}

const QRDetails = (props) =>{
  const [QR, setQR] = useState();
  const [LatestBlock, setLatestBlock] = useState();
  const [isLoaded, setLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState({type: "", message: ""});
  const {QRID} = useParams();
  const [newQR, setNewQR] = useState('');
  const [printItems, setPrintItems] = useState([]);
  const [blockTitle, setblockTitle] = useState(props?.block?.name);
  const [itemChange, setitemChange] = useState(false);
  const [subQRChange, setsubQRChange] = useState(false);
  const [items, setItems] = useState([]);
  const [initialItems, setInitialitems] = useState([]);
  const [QRSwitch, setQRSwitch] = useState(false);
  const [subQRs, setsubQRs] = useState([]);
  const [initialsubQRs, setInitialsubQRs] = useState([]);

    useEffect(()=>{
        async function getQR(){
            try {
                const response = await instance.get(QR_URL + QRID, {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    withCredentials: true
                });
                setQR(response?.data?.QR);
            } catch (error) {
                console.error(error);
                setErrMsg({type: "error", message: "Failed to fetch QR data."});
            }
        }
        getQR();
    }, []);

    useEffect(()=>{
        if(!QR) return;
        async function getLatestBlockData(){
            const lastBlock = QR.Latest_Block;
            if(lastBlock){
                try {
                    const response = await instance.get(Block_URL + lastBlock, {
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                        withCredentials: true
                    });
                    setLatestBlock(response?.data?.block);
                } catch (error) {
                    console.error(error);
                    setErrMsg({type: "error", message: "Failed to fetch latest block data."});
                } finally{
                    setLoaded(true);
                }
            }
            else setLatestBlock(" ");
        }
        getLatestBlockData();
    }, [QR]);

    useEffect(() => {
      if (LatestBlock && LatestBlock.items) {
        // Create a copy of the LatestBlock.items array and modify it for 'items'
        const itemsCopy = LatestBlock.items.map((item) => ({
          ...item,
          locked: true,
        }));
  
        // Set 'items' to the copied and modified array
        setItems(itemsCopy);
  
        // Create another copy for 'initialitems'
        const initialitemsCopy = LatestBlock.items.map((item) => ({
          ...item,
          locked: true,
        }));
  
        // Set 'initialitems' to the copied array (not modified)
        setInitialitems(initialitemsCopy);
      }
    }, [LatestBlock]);

    useEffect(() => {
      if (LatestBlock){
        if(LatestBlock.subQRs && LatestBlock.subQRs.length > 0) {
          let completedRequests = 0;
      
          LatestBlock.subQRs.forEach(async (curBlockId) => {
            try {
              const response = await instance.get(QR_URL + curBlockId, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                withCredentials: true,
              });
      
              if (response.data.QR) {
                setInitialsubQRs((prevSubQRs) => [...prevSubQRs, response.data.QR]);
              }
            } catch (error) {
              console.error(error);
              setErrMsg({ type: 'error', message: 'Failed to fetch latest block data.' });
            } finally {
              completedRequests++;
      
              if (completedRequests === LatestBlock.subQRs.length) {
                setsubQRs(initialsubQRs);
                setLoaded(true);
              }
              console.log("completed requests");
            }
          });
        } 
      }

      else{
        setLoaded(true);
      }

    }, [LatestBlock]);
    
    useEffect(() => {
      if(items && initialItems){
        const areEqual = arraysAreEqual(items, initialItems);
  
        setitemChange(!areEqual);
      }
      
    }, [items]);

    useEffect(()=>{
      const areEqual = arraysAreEqual(subQRs, initialsubQRs);
      console.log("------------")
      console.log(subQRs);
      console.log(initialsubQRs)
      setsubQRChange(!areEqual);
    },[subQRs])

    if (!isLoaded) {
      return (
        <main>
          <p>Loading...</p>
        </main>
      );
    }
    
    async function addBlockRequest() {
      const data = [...items];
      const newSubQRIds = new Set();
  
      try {
        if (subQRChange && subQRs.length > 0) {
          const newSubQRItems = subQRs.filter((item) => item.subQR === true);
          // Using Promise.all to wait for all asynchronous operations to complete
          initialsubQRs.forEach((subQR)=>{
            newSubQRIds.push(subQR._id);
          })
          await Promise.all(
            newSubQRItems.map(async (subQRItem) => {
              try {
                const res = await instance.post(
                  USERQR_URL,
                  { name: subQRItem._id },
                  {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                  }
                );
      
                if (res.data.qr) {
                  newSubQRIds.push(res.data.qr._id);
                  setInitialsubQRs((prevSubQRs) => [...prevSubQRs, res.data.qr]);
                }
              } catch (error) {
                console.log(error);
              }
            })
          );
        }
      } catch (error) {
        console.error(error);
      }

      if (itemChange || subQRChange) {
        for (const item of data) {
          if (!item.title) {
            return;
          }
        }
        const response = await instance.post(
          `${USERQR_URL}${QRID}`,
          {
            empty: items.length ? true : false,
            name: blockTitle,
            items: items,
            subQRs: newSubQRIds,
          },
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: true,
          }
        );
    
        data.forEach((item) => {
          item.locked = true;
        });
    
        setItems(data);
        setInitialitems(data);
      }
    }
  
    function arraysAreEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }
  
      for (let i = 0; i < arr1.length; i++) {
  
        if (!objectsAreEqual(arr1[i], arr2[i])) {
          return false;
        }
      }
  
      return true;
    }
  
    function objectsAreEqual(obj1, obj2) {
      const val1 = Object.values(obj1);
      const val2 = Object.values(obj2);
  
      if (val1.length !== val2.length) {
        return false;
      }
  
      for (const val of val1) {
        if (typeof obj1[val] === 'object' && typeof obj2[val] === 'object') {
          if (!objectsAreEqual(obj1[val], obj2[val])) {
            return false;
          }
        } else if (obj1[val] !== obj2[val]) {
          return false;
        }
      }
  
      return true;
    }
  
    async function addItem() {
      if (items.length + 1 > parseInt(process.env.REACT_APP_ITEM_LIMIT)) {
        props.setErrMsg({
          type: 'Too many items',
          message: `Sorry, you can't have more than ${process.env.REACT_APP_ITEM_LIMIT} items`,
        });
        return;
      }
  
      setItems([...items, { title: '', description: '', quantity: 0, links:[], locked: false }]);
    }
  
    async function deleteItem(index) {
      const data = [...items];
      data.splice(index, 1);
      setItems(data);
    }
  
    async function handleItemDetailsChange(e, index) {
      const data = [...items];
      data[index][e.target.name] = e.target.value;
      setItems(data);
    }
  
    const updateLinksForItem = (newLinks, itemIndex) => {
      const updatedItems = [...items];
      updatedItems[itemIndex].links = newLinks;
      setItems(updatedItems);
    };

    const handleAddQR= async () => {
      setsubQRs([...subQRs, {_id: newQR, name: newQR+ "(Preview)" , subQR:true}])
    };

    function handleExtractQR() {
      extractQR(printItems)
        .then(() => {
          console.log('Extraction completed successfully');
        })
        .catch((error) => {
          console.error('Error during extraction:', error);
        });
    }

    function handlePrintSwitch() {
      setQRSwitch(!QRSwitch);
    }

    function handlePrintSwitch() {
      setQRSwitch(!QRSwitch);
    }
  
    function handleAddPrintItem(item) {
      setPrintItems((prevItems) => [...prevItems, item]);
    }  
  
    function handleDeletePrintItem(index) {
      const updatedItems = printItems.filter((_, i) => _[1] !== index);
      setPrintItems(updatedItems);
    }

      
    return (
        <>
          <Header LoggedIn={true}/>
          <main>
              <div className = "h-content">
                <section className='edit'>
                  <div className="add-block container">
                    <div className = "add-block-box">
                      <input placeholder='Insert new title' onChange={(e) => { setblockTitle(e.target.value) }} />
                    </div>
                    {itemChange || subQRChange ? (
                      <button onClick={addBlockRequest}>Submit block</button>
                    ) : 
                      (<button className={"disable-button"}>Submit block</button>)}
                  </div>
                </section>
                <section className="sub-qr-edit">
                  <div className="add-block container add-qr">
                  {QRSwitch ? (
                    <>
                      <img src={QRimg} alt="QR" />
                      <button onClick={handleExtractQR}> Print QR </button>
                    </>
                    )
                    :(
                      <>
                        <div className="add-block-box">
                          <input type="text" value={newQR} onChange={(e) => setNewQR(e.target.value)} />
                        </div>
                        <button onClick={handleAddQR}>Add Sub QR</button>
                      </>
                    )
                  }
                  </div>
                  {QRSwitch ? (
                        <button className="button-back" onClick={handlePrintSwitch}>Back</button>
                    ) : (
                        <button className="button-export" onClick={handlePrintSwitch}>Export QR</button>
                  )}
                </section>
                  
                      
              
              </div>
              <div className = "content">
                <BlockEdit title={LatestBlock?.name} items={items} addItem={addItem} deleteItem={deleteItem} handleItemDetailsChange={handleItemDetailsChange} updateLinksForItem={updateLinksForItem}/>
                <SubQREdit items={subQRs} displayType={QRSwitch} handleAddQR={handleAddQR} handleAddPrintItem={handleAddPrintItem} handleDeletePrintItem={handleDeletePrintItem}/>
              </div>
                
                  
              <ErrorMessage type={errMsg.type} message={errMsg.message} setErrMsg={setErrMsg}/>
                
          </main>
        </>
        
      );
      
}

export default QRDetails
